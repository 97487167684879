.PopularCategory .PopularWrapper {
    @apply flex gap-[14px] sm:gap-[20px] w-full flex-col  xl:flex-row
}
.PopularCategory .PopularRow {
    @apply flex gap-[14px] sm:gap-[20px] w-full
}
.PopularCategory .PopularCol {
    @apply flex gap-[14px] sm:gap-[20px] flex-col w-full
}
.PopularCategory .PopularCard{
    @apply relative h-full min-h-[180px] md:min-h-[290px] rounded-[8px] sm:rounded-[20px] overflow-hidden
}
.PopularCategory .PopularContent{
    @apply relative z-[1] h-full flex flex-col justify-between
}
.PopularCategory .PopularImg {
    @apply absolute w-full h-full top-0 left-0 object-cover
}
.PopularCategory h3 {
    @apply p-[12px] sm:p-[30px] transition-all duration-300
}
.PopularCategory a {
    @apply flex justify-between items-center px-[12px] py-[8px] sm:px-[30px] sm:py-[12px] font-bold text-[14px] sm:text-[24px] text-white   transition-all duration-300 relative top-[5px] opacity-100 md:opacity-0;
    backdrop-filter: blur(24px);
    background: rgba(80, 0, 86, 0.4);
}
.PopularCategory .PopularIcon {
    @apply w-[18px] sm:w-[40px]
}
.PopularCategory .PopularCard:hover a {
    @apply opacity-100 top-0
}
.PopularCategory .PopularCard:hover h3 {
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.01);
}