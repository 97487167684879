.BasketBtn {
    @apply p-[8px] lg:px-5 lg:py-[10px] lg:h-[50px] border-none bg-a-active-0 transition-all duration-300;
}
.BasketBtn:hover {
    @apply bg-a-buzok-0
}
.BasketBtn:active {
    @apply bg-a-active-0;
    background-color: #500056 !important;
}

.OffcanvasBasket {
    @apply sm:max-w-[360px] sm:border border-a-buzok-0  sm:rounded-tl-[20px];
    border-right: none;
    border-bottom: none;
    border-top: none;
    width: 100% !important;
}
.OffcanvasBasket .offcanvas-header {
    @apply max-h-[60px] relative;
}
.OffcanvasBasket .offcanvas-header::before {
    @apply absolute w-full h-[1px] left-0 bottom-0 ;
    content: "";
    background-image: linear-gradient(to right, transparent, #500056, transparent);
}
.OffcanvasBasket .btn-close {
    @apply transition-all duration-200 ;
}
.OffcanvasBasket.btn-close:hover {
    @apply bg-a-buzok-0
}


.BasketItem {
    @apply flex gap-[20px] items-start relative pb-[8px] my-[20px]
}
.BasketItem::before {
    @apply absolute w-full h-[1px] left-0 bottom-0 ;
    content: "";
    background-image: linear-gradient(to right, transparent, #500056, transparent);
}
.BasketItem .BasketItem__img {
    @apply w-[100px] h-[100px] object-cover rounded-[10px]
}
.BasketItem .BasketItem__trash {
    @apply cursor-pointer
}
.BasketItem .BasketItem__content {
    @apply flex flex-col gap-[8px]
}
.BasketItem .BasketItem__content-top {
    @apply flex gap-[20px] items-start
}
.BasketItem .BasketItem__title {
    @apply font-bold text-[14px] leading-140 text-a-active-0
}
.BasketItem .ProductDetail__bottom {
    @apply flex justify-between items-end
}
.BasketItem .ProductDetail__card-price {
    @apply text-[18px] font-bold text-a-active-0
}


.BasketAll {
    @apply flex flex-col gap-[24px] py-[20px]
}
.BasketAll .BasketAll__priseAll {
    @apply flex justify-between items-center
}
.BasketAll .BasketAll__priseAll-text {
    @apply text-[14px] font-bold leading-140 text-a-active-0
}
.BasketAll .BasketAll__priseAll-price {
    @apply text-[24px] leading-[100%] font-bold text-a-active-0
}
.BasketAll .button {
    @apply text-[16px] text-center py-[16px]
}

