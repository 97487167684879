.Offer .container{
    @apply relative overflow-hidden rounded-[8px] sm:rounded-[20px] px-[20px] py-[30px] sm:py-[52px] sm:px-[76px] bg-[#F5B452]
}
.Offer .OfferImg {
    @apply absolute w-full h-full object-cover top-0 left-0 hidden sm:block
}
.Offer .OfferContent {
    @apply z-[1] relative w-full sm:w-1/2 flex flex-col gap-[20px]
}
.Offer h2 {
    @apply  text-[24px] sm:text-[48px]
}
.Offer .OfferContent p {
    @apply max-w-[560px] text-[16px] leading-160
}