header {
    @apply w-full relative flex justify-center bg-a-gray-0 bg-opacity-80  px-4 sm:px-5 z-[2];
    backdrop-filter: blur(24px);
    background-attachment: fixed;
}

.header__text {
    @apply text-[16px] uppercase leading-120 font-bold;
}

.header__vertical-line {
    @apply lg:hidden w-[1px] h-[30px];
    background-image: linear-gradient(to bottom, transparent, #500056, transparent);
}

.header__info {
    @apply text-[18px] font-medium leading-120 text-center min-w-[160px] hidden lg:block;
}
.headerTop {
    @apply sm:hidden sm:opacity-0;
    transition-duration: 0.2s;
    transition-behavior: allow-discrete;
}

.headerTop.active {
    display: block;
    opacity: 1;
    @starting-style {
        opacity: 0;
    }
}