.HomeHero .heroWrapperTop{
    @apply col-span-3 flex flex-col gap-[20px]
}
.HomeHero .heroCard{
    @apply relative h-[200px] sm:h-[260px] p-[20px] sm:p-[30px] rounded-[12px] sm:rounded-[20px] overflow-hidden flex transition-all duration-500
}
.HomeHero .heroCard:hover{
    @apply scale-[99%]
}
.HomeHero .heroCard-content {
    @apply max-w-[240px] z-[1]
}
.HomeHero .heroCard-content p{
    @apply font-light text-[14px] pt-[10px]
}
.HomeHero .heroCard img{
    @apply absolute w-full h-full left-0 top-0  object-cover transition-all duration-300;
}

.HomeHero .heroCard:hover img {
    filter: opacity(80%);
}
.HomeHero .heroCard.heroWhite:hover img {
    filter: brightness(80%);
}
.HomeHero .heroCard.heroWhite {
    @apply text-white
}
.HomeHero .heroBottom{
    @apply col-span-4
}

