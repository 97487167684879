.ProductDetail .swiper-slide {
    @apply rounded-[12px] sm:rounded-[20px] overflow-hidden
}
.swiper-slide img {
    @apply block w-full h-full object-cover
}
.ProductDetailSwiper2 {
    @apply h-[80%] w-full
}
.ProductDetailSwiper {
    @apply h-[20%] py-[10px] px-0;
    box-sizing: border-box; 
}
.ProductDetailSwiper .swiper-slide {
    @apply w-[25%] h-full opacity-40;
}
.ProductDetailSwiper .swiper-slide-thumb-active {
    @apply opacity-100
}


.ProductDetail__content {
    @apply col-span-12 sm:col-span-7 flex flex-col gap-[20px] sm:gap-[40px]
}
.ProductDetail__content .content__inner {
    @apply flex flex-col gap-[20px]
}
.ProductDetail__content .content__inner .content__description {
    @apply font-bold text-[14px] sm:text-[16px]
}
.ProductDetail__price .old__price{
    @apply text-[12px] line-through font-bold opacity-50 leading-140 
}
.ProductDetail__price .actual__price{
    @apply text-[18px] font-bold leading-140 
}

.ColorPanels {
    @apply flex flex-col gap-[12px]
}
.ColorPanel {
    @apply flex gap-[24px] items-center
}
.ColorPanel span {
    @apply text-[24px] text-a-active-0 font-bold leading-[100%]
}
.ColorSwith {
    @apply flex items-center gap-[12px]
}
.ColorBtn {
    @apply w-[30px] h-[30px] border-a-active-0 rounded-[10px] border-[1px] flex justify-center items-center cursor-pointer
}
.ColorBtn.dark {
    @apply bg-a-active-0 bg-opacity-20
}
.ActiveColor {
    @apply absolute right-[-8px] top-[6px] hidden
}
.ActiveColor.active {
    @apply block
}


.ProductDetail .PriceTable {
    @apply flex flex-col gap-[12px]
}
.ProductDetail .PriceTable div {
    @apply flex w-full sm:max-w-[280px] justify-between font-bold text-[18px] sm:text-[24px] leading-140
}

.ProductDetail .button {
    @apply px-[28px] sm:px-[80px] py-[12px] sm:py-[20px] text-[16px] sm:text-[20px] font-semibold
}

.ProductDetail .info {
    @apply flex flex-col sm:flex-row gap-[12px] sm:gap-[40px] mt-[20px]
}
.ProductDetail .info .infoItem {
    @apply flex gap-[12px] items-start text-[12px] sm:text-[16px] hover:underline transition-all duration-300
}
.ProductDetail .info .infoItemDeskHidden {
    @apply sm:hidden
}
.ProductDetail .info .infoItemMobHidden {
    @apply hidden sm:flex
}