.PrivatPolicy.container {
    @apply pt-[100px]
}
.PrivatPolicy a {
    @apply underline font-bold
}
.PrivatPolicy h1 {
    @apply text-center mb-[80px]
}
.PrivatPolicy h3 {
    @apply mt-[60px] text-center
}
.PrivatPolicy p {
    @apply mt-[40px]
}