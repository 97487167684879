.Sidebar {
    @apply hidden lg:flex flex-col gap-[24px]  w-full max-w-[320px] bg-[#FDDBFF] rounded-[20px] p-[16px] 
}
.Sidebar .MuiAccordion-root {
    @apply bg-transparent shadow-none
}
.Sidebar .MuiPaper-root.Mui-expanded {
    @apply m-0
}
.SidebarTitle.MuiButtonBase-root {
    @apply bg-a-active-0 py-[8px] rounded-[10px]
}
.SidebarTitle .MuiTypography-root {
    @apply font-comfortaa text-[20px] font-bold leading-120 text-white
}
.SidebarTitle .MuiAccordionSummary-content {
    @apply m-0
}
.SidebarTitle .MuiAccordionSummary-expandIconWrapper {
    @apply text-white
}
.SidebarTitle.Mui-expanded {
    min-height: auto !important;
}
.SidebarTitle .MuiAccordionSummary-content.Mui-expanded  {
    margin: 6px 0 !important;
}

.Sidebar .MuiAccordionDetails-root  {
    @apply pb-0  pt-[20px]
}
.Sidebar .SidebarItems {
    @apply flex flex-col gap-[8px]
}
.Sidebar .SidebarItem {
    @apply font-comfortaa text-[16px] leading-120 text-a-black-0 relative transition-all duration-300
}
.Sidebar .SidebarItem:hover {
    @apply underline;
}
.SidebarItem.active {
    text-decoration: underline;
    font-weight: bold;
}