.SearchInput.MuiAutocomplete-root {
    @apply w-full  bg-white rounded-[10px];
}
/* .SearchInput .MuiAutocomplete-input  {
    @apply py-[12px];
    padding: 20px !important
} */

.SearchInput .MuiInputBase-root {
    @apply  rounded-[10px] lg:h-[50px];
    padding: 0px !important;
}
.SearchInput .MuiOutlinedInput-notchedOutline {
    @apply border-transparent 
}
.SearchInput .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    @apply border-a-active-0 border-[1px]
}

.SearchInput.MuiAutocomplete-root label {
    @apply font-comfortaa text-[14px] lg:text-[16px] text-[#656565] bg-white px-[4px] rounded-[6px] top-[-6px] lg:top-[-2px]
}

.SearchInput .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    top: 0!important;
}
