.DeliveryOffer .DeliveryWrapper {
    @apply flex gap-[20px] flex-col md:flex-row
}
.DeliveryOffer .DeliveryImg {
    @apply w-full overflow-hidden rounded-[20px] 
}
.DeliveryOffer img {
    @apply h-full object-cover
}
.DeliveryOffer .DeliveryContent {
    @apply w-full flex flex-col gap-[28px] items-center md:items-start
}
.DeliveryOffer ul {
    @apply mt-[8px]
}
.DeliveryOffer li {
    @apply ml-[15px] list-disc leading-140;
}