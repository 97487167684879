@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
    * {
        scrollbar-color: #DAB7EB #EAE4ED ;
        scrollbar-width: auto;
        box-sizing: border-box;
    }

    html,
    body {
        @apply font-comfortaa text-a-black-0 leading-[140%] text-[18px] bg-[#FFF8FF];
    }

    section {
        @apply flex justify-center  px-4 sm:px-5 py-10;
    }
    a {
        @apply transition-all duration-300
    }

    h1 {
        @apply font-bold leading-120 text-[28px] md:text-[44px]
    }
    h2 {
        @apply font-bold leading-120 text-[28px] md:text-[48px]
    }
    h3 {
        @apply font-bold leading-120 text-[18px] md:text-[32px]
    }
    h4 {
        @apply font-bold
    }
    h5 {
        @apply font-bold text-[18px] sm:text-[28px]
    }
    h6 {
        @apply font-bold text-[20px] leading-160
    }

    


}
@layer components {
    .container {
        @apply w-full max-w-[1440px] p-0;
    }

    .button {
        @apply bg-a-active-0 border border-a-active-0 rounded-[12px] md:rounded-[20px] py-[12px] px-[24px] md:py-4 md:px-10 text-white font-semibold text-[16px] md:text-[20px] inline-block cursor-pointer
    }
    .button:hover {
        @apply bg-white text-a-black-0
    }
    .button.white {
        @apply bg-white text-a-black-0
    }
    .button.white:hover {
        @apply bg-a-active-0 text-white
    }
    .button.buzok {
        @apply bg-a-buzok-0 text-a-black-0 border-none
    }
    .button.buzok:hover {
        @apply bg-a-active-0 text-white
    }
}
@layer utilities {
    /* Hovers */
    .text__hover {
        @apply relative;
    }
    .text__hover::before {
        @apply absolute w-full h-[2px] left-0 bottom-0 rounded-[2px];
        background-image: linear-gradient(to right, transparent, #500056, transparent);
        transition: transform 0.3s ease-out;
        content: "";
        transform: scaleX(0);
    }
    .text__hover:hover::before {
        transform: scaleX(1);
    }

    .text__hover.active::before {
        @apply absolute w-full h-[2px] left-0 bottom-0 rounded-[2px];
        background-image: linear-gradient(to right, transparent, #500056, transparent);
        transition: transform 0.3s ease-out;
        content: "";
        transform: scaleX(1);
    }
    .line__horisont {
        @apply w-full h-[1px];
        background-image: linear-gradient(to right, transparent, #500056, transparent);
    }

    /* PopularCategoryBlur */
    .blurWhite {
        backdrop-filter: blur(1px);
        background: rgba(255, 255, 255, 0.01);
    }
}





