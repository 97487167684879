.Contact {
    @apply relative overflow-hidden min-h-[830px] sm:min-h-[100%] py-[90px] sm:py-[120px]
}
.Contact .ContactMap {
    @apply absolute top-0 left-0 w-full h-full ;
}
.Contact iframe {
    @apply w-full sm:w-[140%] h-[170%] sm:h-[100%]
}
.Contact .container {
    @apply relative z-[1] pointer-events-none
}
.Contact .ContactContent {
    @apply p-[16px] sm:p-[40px] rounded-[20px] max-w-[630px] pointer-events-auto flex flex-col gap-[28px];
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.6);
}

