.login-section {
    @apply  h-[100vh] bg-[#e9c5f0];
}

.login-container {
    @apply max-w-[1440px] w-full px-5;
}

.login-inner {
    @apply flex flex-col gap-4 items-center justify-center mt-[100px] w-full;
}

.login-top {
    @apply flex items-center gap-3
}

.login-title {
    @apply text-xl text-[#9c27b0];
}