.Returns {
    @apply py-[40px] sm:py-[80px];
    background-image: url('../../Assets/Img/Returns/blue-balloon.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.Returns img {
    @apply sm:hidden rounded-[12px] object-cover aspect-square;
}
.Returns .content{
    @apply w-full sm:w-1/2 flex flex-col gap-[60px]
}
.Returns .content p{
    @apply leading-160
}


.Returns h1{
    @apply text-[32px] text-center mt-[20px]
}
.Returns h3{
    @apply text-[32px] text-center mt-[20px]
}
.Returns h6{
    @apply text-[24px]
}
.Returns li{
    @apply text-[16px] leading-[160%] ml-[16px];
    list-style-type: disc;
}