.MobManuBtn {
    @apply lg:hidden bg-transparent border-transparent m-0 p-0;
}
.MobManuBtn:hover {
    @apply bg-transparent;
}
.MobManuBtn:active {
    background-color: transparent !important;
    border: none;
}

.header__text {
    @apply text-[16px] uppercase leading-120 font-bold;
}

.OffcanvasMobManu {
    height: 100% !important;
    width: 100% !important;
}

.OffcanvasMobManu .offcanvas-header {
    @apply max-h-[60px] relative;
}

.OffcanvasMobManu .offcanvas-title {
    @apply flex justify-center;
}

.OffcanvasMobManu .offcanvas-header::before {
    @apply absolute w-full h-[1px] left-0 bottom-0 ;
    content: "";
    background-image: linear-gradient(to right, transparent, #500056, transparent);
}

.OffcanvasMobManu .btn-close {
    @apply transition-all duration-200 
}

.OffcanvasMobManu .btn-close:hover {
    @apply bg-a-buzok-0
}



