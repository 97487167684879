.FilterSelect .MuiFormControl-root {
    @apply bg-white rounded-[6px] sm:rounded-[10px] min-w-[140px]
}
.FilterSelect .FilterPrice.MuiFormControl-root {
    @apply sm:min-w-[170px]
}
.FilterSelect .FilterColor.MuiFormControl-root {
    @apply max-w-[100px]
}
.FilterSelect .MuiSelect-select {
    @apply py-[8px] sm:py-[14px]
}
.FilterSelect .MuiFormLabel-root {
    @apply font-comfortaa text-a-active-0 font-bold text-[14px] sm:text-[16px] top-[-8px] sm:top-0
}
.FilterSelect .MuiInputLabel-root.Mui-focused {
    @apply  text-a-active-0 top-0
}
.FilterSelect .MuiInputBase-root {
    @apply font-comfortaa text-a-active-0 font-bold border-a-active-0 text-[14px] sm:text-[16px]
}
.FilterSelect .MuiOutlinedInput-notchedOutline {
    @apply border-a-active-0 rounded-[6px] sm:rounded-[10px] ;
    border-color: #500056 !important
}
.FilterSelect .MuiMenuItem-root {
    @apply font-comfortaa
}