.MuiButtonBase-root.CatalogBtn {
    @apply flex  gap-[20px] p-[8px] min-h-[40px] lg:px-5 lg:py-[10px] lg:h-[50px] rounded-[6px] lg:rounded-[10px] bg-a-active-0 min-w-[auto];
}
.MuiButtonBase-root.CatalogBtn:hover {
    @apply bg-a-active-0
}
.MuiButtonBase-root.CatalogBtn p{
    @apply text-[20px] font-semibold font-comfortaa leading-120 hidden lg:block text-white;
    text-transform: none;
}

.CatalogBtnOnclick .MenuItem {
    @apply font-comfortaa text-[18px] text-a-black-0 font-medium px-6 
}

.CatalogBtnOnclick .MenuItem.category {
    @apply font-bold px-4 cursor-auto
}
  
.CatalogBtnOnclick .MenuItem.category:hover {
    @apply bg-transparent
}
.CatalogBtnOnclick {
    max-height: 500px; /* или любое другое значение по вашему усмотрению */
    overflow-y: auto;
  }
  
 