.Delivery {
    @apply py-[40px] sm:py-[280px] bg-cover bg-no-repeat bg-top ;
}
.Delivery .content{
    @apply w-full sm:w-1/2 flex flex-col gap-[20px] sm:gap-[40px]
}
.Delivery .content p{
    @apply leading-160
}
.Delivery h6{
    @apply text-[26px]
}
.Delivery li{
    @apply text-[16px] leading-[160%] ml-[16px];
    list-style-type: disc;
}