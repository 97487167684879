.Checkout__title {
    @apply text-[28px] sm:text-[36px] font-bold leading-120
}
.Checkout .impot {
    @apply text-[#FF0000]
}
.Checkout input {
    @apply bg-white rounded-[10px] font-comfortaa text-a-black-0 px-[18px] py-[12px] text-[16px] ;
}
.Checkout textarea {
    @apply bg-white rounded-[10px] font-comfortaa text-a-black-0 px-[18px] py-[12px] text-[16px] ;
}
.Checkout .MuiInputBase-root.MuiOutlinedInput-root {
    @apply p-0
}
.Checkout fieldset {
    @apply border-transparent rounded-[10px]
}
.Checkout .Mui-focused fieldset  {
    @apply border-[#500056];
    border-color: #500056 !important; 
}
.Checkout label {
    @apply text-[18px] sm:text-[24px] font-bold font-comfortaa text-a-black-0
}

.Checkout .MuiRadioGroup-root  {
    @apply flex flex-col gap-[20px]
}
.Checkout .MuiRadio-root.Mui-checked  {
    @apply text-a-active-0
}
.Checkout .MuiTypography-root  {
    @apply text-a-black-0 font-comfortaa font-bold text-[18px] sm:text-[24px]
}
.Checkout .MuiTypography-root a {
    @apply underline
}




.bg__basket-title::before {
    @apply absolute w-full h-[1px] left-0 bottom-0 ;
    content: "";
    background-image: linear-gradient(to right, transparent, #500056, transparent);
}
.Checkout__policy {
    @apply text-[12px] text-left sm:text-center;
}
.Checkout__policy a {
    @apply underline
}