.FilterBar {
    @apply flex justify-between flex-col lg:flex-row gap-[20px] items-start
}
.FilterBar .filter__btn {
    @apply px-[12px] py-[4px] sm:px-[20px] sm:py-[8px] border-[1px] border-a-active-0 bg-white rounded-[6px] sm:rounded-[10px] text-a-active-0 font-bold text-[14px] sm:text-[16px] cursor-pointer hover:scale-[98%] transition-all duration-300 flex items-center;
    white-space: nowrap;
}

.FilterBar .filter__btn.active {
    @apply text-white bg-a-active-0
}

