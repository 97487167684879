.Contacts {
    @apply py-[40px] sm:py-[80px];
    /* background-image: url('../../Assets/Img/Contacts/bg.webp'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.Contacts img {
    @apply sm:hidden rounded-[12px] object-cover aspect-square;
}
.Contacts .content{
    @apply w-full sm:w-1/2 flex flex-col gap-[20px] sm:gap-[40px]
}
.Contacts .content p{
    @apply leading-160
}