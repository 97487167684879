footer {
    @apply flex justify-center  px-4 sm:px-5 bg-[#FDDBFF]
}
footer .container {
    @apply py-10
}
.Footer .FooterWrapper {
    @apply flex justify-between flex-col md:flex-row gap-[40px] md:gap-[20px]
}
.Footer .FooterCol {
    @apply flex flex-col gap-[20px] sm:gap-[28px]
}
.Footer .FooterTitle {
    @apply text-[28px] font-bold 
}
.Footer .FooterItem {
    @apply text-[18px] hover:underline transition-all duration-300
}
.Footer .FooterList {
    @apply flex flex-col gap-[16px]
}
.Footer .FooterTitle.last {
    @apply flex justify-center md:justify-start
}
.Footer .FooterList.last {
    @apply  items-center md:items-start
}
.Footer .FooterItem.last {
    @apply text-[14px]
}
.Skoyar {
    @apply bg-[#0A0E10] py-[8px] w-full flex justify-center
}
.Skoyar img {
    @apply w-[120px] 
}