.HeroSlider {
    @apply col-span-3 md:col-span-9 
}
.HeroSlider .swiper{
    @apply h-full 
}
.HeroSlider .heroSlide {
    @apply relative  h-full  p-[20px] pb-[40px] sm:p-[30px] rounded-[12px] sm:rounded-[20px] overflow-hidden
}
.HeroSlider h2 {
    @apply text-white
}
.HeroSlider h1 {
    @apply text-white
}
.HeroSlider .heroSlide img{
    @apply absolute w-full h-full left-0 top-0 z-[-1] object-cover
}
.HeroSlider .heroSlide-content {
    @apply flex flex-col gap-[24px] max-w-[600px] items-start
}
.HeroSlider .heroSlide-content a {
    
}

.HeroSlider li {
    @apply text-[16px] font-light leading-[160%] ml-[16px];
    list-style-type: disc;
}
.HeroSlider li p {
    @apply text-white
}
.HeroSlider p {
    @apply text-white text-[16px]
}
.swiper-pagination-bullet {
    background: #ffffff !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active  {
    background: #500056 !important;
}
