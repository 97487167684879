.HomePromo {
    @apply overflow-hidden pb-[70px]
}
.HomePromo .swiper{
    @apply  overflow-visible
}
.HomePromo .swiper-wrapper{
    @apply overflow-visible 
}
.HomePromo .swiper-slide  {
    @apply relative  h-[300px] sm:max-w-[345px] rounded-[12px] sm:rounded-[20px] overflow-hidden 
}
.HomePromo img {
    @apply absolute w-full h-full left-0 top-0 z-[-1] object-cover
}
.HomePromo .PromoSlideContent{
    @apply flex flex-col justify-between h-full items-start;
}
.HomePromo .PromoPrice {
    @apply py-[8px] px-[20px] bg-a-red-0 bg-opacity-40 rounded-br-[12px] sm:rounded-br-[20px] text-white font-bold text-[32px] leading-120
}
.HomePromo .PromoText {
    @apply bg-a-active-0 bg-opacity-40 py-[8px] px-[20px] leading-140 text-white w-full
}
.HomePromo .swiper-scrollbar.swiper-scrollbar-horizontal {
    @apply bottom-[-30px] h-[10px] bg-a-gray-0 rounded-[30px]
}
.HomePromo .swiper-scrollbar-drag {
    @apply bg-a-buzok-0 rounded-[30px]
}